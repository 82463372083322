<template>
  <li class="memorialHallItem" @click="goDetail(item.memorialInfoId)">
    <div class="item_avatar">
      <img class="avatar" v-if="item.avatar" :src="item.avatar" alt="" />
                <img class="avatar" v-else :src="deadImg" alt="" />
    </div>
    <div class="item_info">
      <h3>{{ item.name }}</h3>
      <div class="life">
        <span>{{ item.life }}</span>
      </div>
      <div class="leave">
        <span>离世{{ item.leave }}年</span>
      </div>
    </div>
    <div class="label">
      <div class="tips" v-if="item.friendsType == 'glz'">
        <span>管理者</span>
      </div>
    </div>
    <div class="many" v-if="item.memorialType == 2">
      <span>双人馆</span>
    </div>
  </li>
</template>

<script>
export default {
  data() {
    return {
       deadImg: require('@/assets/imgs/deadImg.png'),
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  methods: {
    goDetail(id) {
      this.$router.push(`/memorialHall/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.memorialHallItem {
  width: 100%;
  padding: 0.05rem 0.07rem;
  position: relative;
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  background: #f7f7f7;
  color: $textColor;
  margin-bottom: 0.07rem;
  overflow: hidden;
  border-radius: 0.05rem;
  .item_avatar {
    width: 0.7rem;
    height: 0.85rem;
    margin-right: 0.09rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.05rem;
    }
  }
  .item_info {
    h3 {
      margin-top: 0.04rem;
      font-size: 16px;
      line-height: 0.23rem;
    }
    .life {
      font-size: 14px;
      line-height: 0.2rem;
      margin-bottom: 0.06rem;
    }
    .leave {
      font-size: 13px;
      line-height: 0.19rem;
      color: $lightBlack;
    }
  }
  .label {
    position: absolute;
    right: 0;
    top: 0;
  }
  .tips {
    // position: absolute;
    // right: 0;
    // top: 0.1rem;
    margin-top: 0.05rem;
    background: $defaultColor;
    color: #fff;
    padding: 0.03rem 0.09rem;
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
    span {
      font-size: 14px;
      line-height: 0.2rem;
    }
  }
  .many {
    position: absolute;
    background: $defaultColor;
    padding: 0.02rem 0.15rem;
    color: #fff;
    left: -0.16rem;
    top: 0.08rem;
    // z-index: 1;
    transform: rotate(-45deg);
    span {
      font-size: 12px;
      //   line-height: 0.2rem;
    }
  }
}
</style>
