import axios from 'axios'
import store from '@/store'
import {Toast} from 'vant'
import { getToken,removeToken } from '@/utils/auth'
import { encrypt,checkDigest,decrypt} from '@/utils/crypto'
import router from '@/router'
let mode=3;//判断是否加密
let toast=null//loading
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 ,// request timeout
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
})
// 请拦截
service.interceptors.request.use(
  config => {
    //loading
    toast=Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: '加载中',
    });
    // do something before request is sent
    let ts=(new Date()).valueOf();//时间戳
    let token = getToken()//token
    if (token) {
      config.headers['token'] = token
    }
     console.log(config.data,'加密前参数')
    if(mode===3){
      config.data=encrypt(JSON.stringify(config.data));
      // console.log(config.data,'加密后参数')
    }else if(mode===0){
      let postData={
        msgId:'uid',
        token,
        mode:0,
        body:JSON.stringify(config.data),
        digest:null,
        ts
      }
      // console.log(postData,'铭文状态传输数据')
      config.data=postData
    }
    
    // console.log(config.data,'加密后参数')
    return config
  },
  error => {
    // do something with request error
    toast.clear()//清除loading
    console.log(error) // for debug
     return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    toast.clear()//清除loading
    // console.log('response',response);
    // return response.data
    if (response.data.result == -99) {   //token 失效
      Toast.fail('token失效');
      // console.log('1',response.data)
      localStorage.removeItem('token')
      localStorage.removeItem('code')
      router.push("/login");
      return 
    }
    if(response.data.mode==3){
      var res = response.data
      if (res.result != 0) {
        // console.log('2',response.data)
        Toast.fail(res.msg || "请求失败");
        if (res.code == 2) {   //token 失效
          localStorage.removeItem('token')
          localStorage.removeItem('code')
          router.push("/login");
         // location.reload()
        }
        return Promise.reject(new Error(res.msg || 'Error')).catch(err => {
          // console.log(err) // 这里catch到错误
        })
      } else {
        //  console.log(res,'验签数据')
            //先验签报文摘要
          let flag=checkDigest(res.digest,res.body,res.ts);
          // console.log(flag,'校验结果')
          if(flag){
            let result=decrypt(res.body,'UX00H5n2BN7L4Y5H');
              // console.log(result,result.code,result.data,'7878')
            return result
          }else{
            Toast.fail("验签失败");
            return false
          }
        }
    }else if(response.data.mode==0) {
      if(response.data.result!=0){
        Toast.fail('请求数据错误')
        return 
      }
      //不需要解密
      var res = response.data.body
      if (res.code == 1) {
        return res
      } else {
        Toast.fail(res.msg || "请求失败");
  
        if (res.code == 2) {   //token 失效
          localStorage.removeItem('token')
          router.push("/login");
          //location.reload()
        }
        return Promise.reject(new Error(res.msg || 'Error')).catch(err => {
          // console.log(err) // 这里catch到错误
        })
      }
    }
    
    // console.log(res)
    // if the custom code is not 20000, it is judged as an error.
   
  },
  error => {
    console.log('err' + error) // for debug
    Toast.fail(error || "请求失败");
    // return Promise.reject(error)
  }
)

export default service
