import request from '@/utils/request'
export function getOpenId(data) {
  return request({
     url: '/weixin/getUserInfoByCode',
    method: 'post',
    data
  })
}
//获取用户状态（是否注册）
export function getUserStatus(data) {
  return request({
    url: '/sys/user/getUserByPhone',
    method: 'post',
    data
  })
}
//获取验证码
export function getVerificationCode(data) {
  return request({
    url: '/user/message/toSendNoToken',
    method: 'post',
    data
  })
}
//用户登录
export function login(data) {
  return request({
    url: '/sys/user/login',
    method: 'post',
    data
  })
}
//注册
export function register(data) {
  return request({
    url: '/sys/user/register',
    method: 'post',
    data
  })
}
// //退出
export function logout(data) {
  return request({
    url: '/sys/user/logout',
    method: 'post',
    data
  })
}
//获取用户信息
export function getUserInfo(data) {
  return request({
    url:'/app/user/selectOne',
    method: 'post',
    data
  })
}
//校验短信验证码
export function checkMsgCode(data) {
  return request({
    url:'/sys/user/changeCode',
    method: 'post',
    data
  })
}
// //修改密码
export function changePassword(data){
  return request({
    url: '/sys/user/changePws',
    method: 'post',
    data
  })
}

