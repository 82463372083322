// import request from '@/utils/http'
import request from "@/utils/request";

//获取首页顶部我的纪念馆信息
export function memorialInfo_manage(data) {
  return request({
    url: "/app/memorialInfo/manage",
    method: "post",
    data,
  });
}

// //获取首页纪念馆列表信息
export function memorialInfo_search(data) {
  return request({
    url: "/app/memorialInfo/search",
    method: "post",
    data,
  });
}
// 加载banner列表
export function getBannerList(data) {
  return request({
    url: "/app/advertList/getbannerlist",
    method: "post",
    data,
  });
}
// 加载滚动消息列表
export function getmessagelist(data) {
  return request({
    url: "/app/message/getmessagelist",
    method: "post",
    data,
  });
}
