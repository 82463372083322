const TokenKey = "token";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}

export function setUserInfo(info) {
  return localStorage.setItem("userInfo", JSON.stringify(info));
}

export function getUserInfo() {
  let obj = localStorage.getItem("userInfo");
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    obj = {};
  }
  return obj;
}
export function removeUserInfo() {
  return localStorage.removeItem("userInfo");
}

export function setUserId(info) {
  return localStorage.setItem("userId", JSON.stringify(info));
}
export function getUserId() {
  return JSON.parse(localStorage.getItem("userId"));
}

//清除所有
export function clearLocalStorage() {
  return localStorage.clear();
}
