<template>
<div class="navbar">
    <van-nav-bar
      :style="{ backgroundColor: backgroundColor}"
      :title="title"
      :left-text="leftText"
      :right-text="rightText"
      :left-arrow="leftArrowFlag"
      :fixed='fixed'
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <slot></slot>
    </template>
    </van-nav-bar>
    </div>
</template>

<script>
import variables from "@/assets/styles/variables.scss";
export default {
  name: "navBar",
  data() {
    return {};
  },
  props: {
    backgroundColor: {
      type: String,
      default: variables.defaultColor,
    },
    title: {
      type: String,
      default: "首页",
    },
    leftText: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
    leftArrowFlag: {
      type: Boolean,
      default: false,
    },
    fixed:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    //点击左侧
    onClickLeft() {
      // this.$emit("clickNavBarLeft");
       this.$router.go(-1)
    },
    //点击右侧
    onClickRight() {
      this.$emit("clickNavBarRight");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar{
    .van-nav-bar{
        /deep/ .van-icon{
            color: #fff;
        }
        /deep/ .van-nav-bar__text{
            color: #fff;
        }
        /deep/ .van-nav-bar__right{
          color: #fff;
        }
    }
}
</style>