<template>
  <van-tabbar v-model="active" class="tabbar" route>
    <van-tabbar-item
      v-for="(item, index) in tabBarList"
      :key="index"
      replace
      :to="item.path"
    >
      <span>{{ item.text }}</span>
      <template #icon="props">
        <i :class="item.icon" class="iconfont"></i>
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      tabBarList: [
        {
          id: 0,
          text: "首页",
          icon: "icon-shouyex",
          path: "/home",
        },
        {
          id: 1,
          text: "动态",
          icon: "icon-dongtai_",
          path: "/news",
        },
        {
          id: 2,
          text: "预约",
          icon: "icon-yuyuejilu",
          path: "/appointment",
        },
        {
          id: 3,
          text: "我的",
          icon: "icon-wode",
          path: "/my",
        },
      ],
    };
  },
  mounted(){
    this.tabBarList = [
        {
          id: 0,
          text: "首页",
          icon: "icon-shouyex",
          path: "/home",
        },
        {
          id: 1,
          text: "动态",
          icon: "icon-dongtai_",
          path: "/news",
        },
        {
          id: 2,
          text: "预约",
          icon: "icon-yuyuejilu",
          path: "/appointment",
        },
        {
          id: 3,
          text: "我的",
          icon: "icon-wode",
          path: "/my",
        },
      ]
  },
};
</script>

<style lang="scss" scoped>
.tabbar {
  .van-tabbar-item {
    color: #888888;
    .van-tabbar-item__icon {
      margin-bottom: 0;
      .iconfont {
        font-size: 20px;
        color: #dbdbdb;
      }
    }
  }
  .van-tabbar-item--active {
    color: $defaultColor;
    .van-tabbar-item__icon {
      .iconfont {
        color: $defaultColor;
      }
    }
  }
}
</style>
