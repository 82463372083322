// 引入CryptoJS包
import CryptoJS from 'crypto-js'
import md5 from 'js-md5';
// AES加密密钥
const aes_key = 'UX00H5n2BN7L4Y5H';
// AES加密
export function encrypt(data, keyStr) { 
    
    let ts=(new Date()).valueOf();//时间戳
    keyStr = keyStr ? keyStr : aes_key;
    var key  = CryptoJS.enc.Utf8.parse(keyStr);
    var srcs = CryptoJS.enc.Utf8.parse(data);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    let body=window.btoa(encrypted.toString());
    let encryptData={
        msgId:'uid',
        token:localStorage.getItem('token'),
        mode:3,
        body,
        digest:md5(`${body}${ts}`),
        ts
    }
    
    return encryptData;
}
//验签报文摘要
export function checkDigest(digest,body,ts) { 
    let data = md5(`${body}${ts}`)
    if(digest===data){
        return true
    }else{
        return false
    } 
}
// AES解密
export function  decrypt(body, keyStr){ 
    let data=window.atob(body) 
    // console.log(data,keyStr,'解密数据');
    keyStr = keyStr ? keyStr : aes_key;
    // console.log(keyStr,'解密数据1');
    var key  = CryptoJS.enc.Utf8.parse(keyStr);
    // console.log(key,'解密数据2');
    var decrypt = CryptoJS.AES.decrypt(data, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    // console.log(decrypt,'解密数据4');
    let res= JSON.parse(CryptoJS.enc.Utf8.stringify(decrypt).toString())
    // console.log(typeof(res),res.code,'000')
    return res
    //return window.atob(CryptoJS.enc.Utf8.stringify(decrypt).toString());
}