import dayjs from "dayjs";
const timeFormat = (val) => {
  return dayjs(val).format("YYYY-MM-DD");
};
const timeFormat1 = (val) => {
  return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
};


const pastTime = (val) => {
  let now = new Date().getTime();
  let dateDiff = now - val;
  var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
  var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数 //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数 //计算相差秒数
  var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000);
  if (dayDiff > 0) {
    return dayDiff + "天前";
  } else if (hours > 0) {
    return hours + "小时前";
  } else if (minutes > 0) {
    return minutes + "分钟前";
  } else {
    return "刚刚";
  }
};

export { timeFormat, timeFormat1, pastTime };
