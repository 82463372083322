<template>
  <div class="home pb50">
    <!-- 搜索框 -->
    <div class="search_box" @click="goSearch">
      <van-search disabled shape="round" placeholder="在此输入纪念馆名" />
    </div>
    <!-- 轮播 -->
    <van-swipe class="my_swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in swipers" :key="index">
        <img v-lazy="item.imgUrl" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- 消息轮播 -->
    <van-swipe
      class="news_list"
      :autoplay="3000"
      :show-indicators="false"
      :vertical="true"
    >
      <van-swipe-item
        class="news_item"
        v-for="(item, index) in news"
        :key="index"
      >
        <div class="news_item_left">
          <img class="avatar" v-if="item.headUrl" :src="item.headUrl" alt="" />
          <img class="avatar" v-else :src="defaultAvatar" alt="" />
          <span class="name">{{ item.nickName|| '匿名' }}</span>
          <span class="detail">{{ item.title }}</span>
        </div>
        <div class="news_item_right">
          <span class="lightBlack">{{ item.gmtCreate | pastTime }}</span>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- 分割hr -->
    <div class="mb10"></div>
    <!-- 我的殡仪馆 -->
    <div class="my_memorial">
      <itemTitle title="我的纪念馆">
        <template v-slot:right>
          <router-link
            class="check_more lightBlack"
            to="/my/iManageMemorialHalls"
          >
            <span>查看更多</span>
            <van-icon name="arrow" />
          </router-link>
        </template>
      </itemTitle>
      <div class="my_memorial_content">
        <div class="top_content">
          <ul
            class="memorial_list"
            v-if="myMemorialHallList && myMemorialHallList.length > 0"
          >
            <homeItem
              v-for="(item, index) in myMemorialHallList"
              :key="index"
              :item="item"
            >
            </homeItem>
          </ul>
          <div class="no_memorial_list" v-else>
            <span>暂无纪念馆，快去创建吧！</span>
          </div>
        </div>
        <div class="create_btn" @click="goCreate">
          <span>+创建纪念馆</span>
        </div>
      </div>
    </div>
    <!-- 分割hr -->
    <div class="mb10"></div>
    <!-- 纪念馆列表 -->
    <div class="memorial_hall_list">
      <itemTitle title="纪念馆">
        <template v-slot:right>
          <router-link
            class="check_more lightBlack"
            to="/home/memorialHallList"
          >
            <span>查看更多</span>
            <van-icon name="arrow" />
          </router-link>
        </template>
      </itemTitle>
      <div class="memorial_content">
        <ul
          class="memorial_list"
          v-if="memorialHallList && memorialHallList.length > 0"
        >
          <homeItem
            v-for="(item, index) in memorialHallList"
            :key="index"
            :item="item"
          >
          </homeItem>
        </ul>
        <div class="no_memorial_list" v-else>
          <span>暂无纪念馆，快去创建吧！</span>
        </div>
      </div>
    </div>
    <tabBar :activeId="0" :key="timer"/>
    <van-overlay class="overlay_box" :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="count">
          <span>倒计时 {{ time }} S</span>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import navBar from "@/components/navBar";
import tabBar from "@/components/tabBar";
import itemTitle from "@/components/itemTitle";
import homeItem from "@/components/memorialHallItem/homeItem.vue";
import {
  memorialInfo_search,
  memorialInfo_manage,
  getBannerList,
  getmessagelist,
} from "@/api/home/index";
export default {
  name: "home",
  components: {
    navBar,
    tabBar,
    vueSeamlessScroll,
    itemTitle,
    homeItem,
  },
  data() {
    return {
      show: false,
      time: 3,
      defaultAvatar: require("@/assets/imgs/avatar.png"),
      pageSize: 5,
      currentPage: 1,
      swipers: [],
      news: [],
      myMemorialHallList: [],
      memorialHallList: [],
      timer: ''
    };
  },
  created() {
    // 加载banner列表
    this.getbannerlist();
    let isFirstInHome = sessionStorage.getItem("isFirstInHome");
    if (isFirstInHome == null) {
      this.show = true;
      sessionStorage.setItem("isFirstInHome", false);
    } else {
      this.show = false;
    }
    this.countDown(this.time);
  },
  mounted() {
    // 加载滚动消息列表
    this.getmessagelist();
    //加载我管理的纪念馆
    this.onLoadTop();
    //加载纪念馆
    this.onLoadList();
    this.$nextTick(()=>{
      this.timer = new Date().getTime()
    })
    
    //
    // let  isFirstInHome= sessionStorage.getItem('isFirstInHome')
    // if(isFirstInHome){
    //   sessionStorage.setItem('isFirstInHome',false)
    // }else{

    // }
  },
  methods: {
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.show = false;
        return;
      } else {
        this.time = time;
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    goSearch() {
      this.$router.push("/search");
    },
    goCreate() {
      this.$router.push("/createMemorialHall");
    },
    // 加载banner列表
    async getbannerlist() {
      const res = await getBannerList({});
      if (res.code === "2000") {
        this.swipers = res.data;
        return;
      } else {
        this.$toast.fail(res.msg || "数据请求失败");
      }
    },
    // 加载滚动消息列表
    async getmessagelist() {
      let postData = {
        messageType: 3,
      };
      try {
        const res = await getmessagelist(postData);
        console.log(res, "消息");
        if (res.code === "2000") {
          this.news = res.data;
          return;
        }
        this.$toast.fail(res.msg || "消息加载失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg || "消息加载失败");
      }
    },
    //加载我管理的纪念馆
    async onLoadTop() {
      let postData = {
        friendsTypes: ["glz"], //关联类型(gz:关注;qy:亲友;glz管理者)
        currentPage: 1,
        pageSize: 1,
      };
      console.log(postData, "postData");
      try {
        const res = await memorialInfo_manage(postData);
        console.log(res, 2000);
        if (res.code === "2000") {
          let list = JSON.parse(JSON.stringify(res.data)) || [];
          let newtime = new Date().getFullYear();
          list.forEach((item) => {
            // 在世时间处理
            item.life = item.birthday + " ~ " + item.deathDay;
            // 图片
            item.avatar = item.deadPortraitUrl;

            // 离世时长;
            item.leave = item.passAwayYear;
            // 纪念馆名称
            item.name = item.deadName;
          });

          this.myMemorialHallList = list;
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg || "数据请求失败");
      }
      return;
    },
    //加载纪念馆
    async onLoadList() {
      const { currentPage, pageSize } = this;
      let postData = { currentPage: 1, pageSize: 5 };
      let that = this;
      try {
        const res = await memorialInfo_search(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          const list = JSON.parse(JSON.stringify(res.data));
          list.forEach((item) => {
            item.memorialInfoId = item.id;
            // 在世时间处理
            
            item.life = item.segDates ? item.segDates[0] : "";
            // 图片
            item.avatar = item.deadPortraitUrls ? item.deadPortraitUrls[0] : "";

            // 离世时长;
            item.leave = item.deathYears ? item.deathYears[0] : "";
            // 纪念馆名称
            //item.name = item.deadNames ? item.deadNames[0] : "";
            let name='';
            item.deadNames.forEach(item=>{
              name+=item+','
            })
            item.name = name.substring(0,name.length-1);
          });
          console.log(list[0]);
          this.memorialHallList = list;
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg || "数据请求失败");
      }
    },
  },
  beforeDestroy() {
    //sessionStorage.setItem('isFirstInHome',false)
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  .overlay_box {
    z-index: 3;
    .wrapper {
      width: 100%;
      height: 100%;
      background: url("../../assets/imgs/homeBg.jpg") no-repeat top center;
      background-size: 100% 100%;
      .count {
        position: absolute;
        top: 0.2rem;
        right: 0.1rem;
        width: 1rem;
        height: 0.3rem;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.3rem;
      }
    }
  }

  // 轮播
  .my_swipe {
    width: 100%;
    height: 2rem;
    .van-swipe-item {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  // 搜索框
  .search_box {
    position: absolute;
    padding: 0 0.32rem;
    top: 0.1rem;
    left: 0;
    width: 100%;
    z-index: 2;
    /deep/ .van-search {
      padding: 0;
      background: transparent;
      .van-search__content {
        .van-cell {
          padding: 0.05rem 0 !important;
        }
      }
    }
  }
  //消息轮播
  .news_list {
    width: 100%;
    height: 0.44rem;
    .news_item {
      width: 100%;
      height: 100%;
      padding: 0 0.1rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      .news_item_left {
        flex: 1;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 0.1rem;
        width: calc(100% - 60px);
        .avatar {
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          margin-right: 0.07rem;
        }
        .name {
          color: $defaultColor;
          // font-size: 12px;
          // margin-right: 0.1rem;
          text-align: center;
          font-size: 0.13rem;
          width: 0.7rem;
          // width: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .detail {
          width: calc(100% - 0.8rem);
          font-size: 0.13rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .news_item_right {
        // width: 0.9rem;
        // width: 60px;
        font-size: 0.13rem;
        text-align: right;
      }
    }
  }
  //我的纪念馆
  .my_memorial {
    padding: 0 0.12rem;
    .top_content {
      min-height: 0.9rem;
      .no_memorial_list {
        text-align: center;
        line-height: 0.5rem;
      }
    }
    .create_btn {
      width: 100%;
      height: 0.44rem;
      background: $defaultColor;
      color: #fff;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      border-radius: 0.05rem;
      box-shadow: $boxShow;
      margin: 0.15rem 0;
    }
  }
  //纪念馆
  .memorial_hall_list {
    padding: 0 0.12rem;
  }
}
</style>
