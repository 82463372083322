import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home";
import getPageTitle from "@/utils/get-page-title";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  //---------------------登录用户相关-------------------------------------
  {
    path: "/login",
    name: "login",
    meta: { title: "用户登录", next: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/index.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    meta: { title: "", next: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/auth.vue"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    meta: { title: "忘记密码" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/login/forgetPassword.vue"
      ),
  },
  {
    path: "/resetPassword/:phone",
    name: "resetPassword",
    meta: { title: "重置密码" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/login/resetPassword.vue"
      ),
  },
  {
    path: "/alterPassword/:phone",
    name: "alterPassword",
    meta: { title: "修改密码" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/login/alterPassword.vue"
      ),
  },
  {
    path: "/loginRegister",
    name: "loginRegister",
    meta: { title: "注册登录", next: true },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/login/loginRegister.vue"
      ),
  },
  {
    path: "/passwordRegister",
    name: "passwordRegister",
    meta: { title: "注册登录", next: true },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/login/passwordRegister.vue"
      ),
  },
  // {
  //   path: '/binding',
  //   name: 'binding',
  //   meta:{title:'绑定',next:true},
  //   component: () => import(/* webpackChunkName: "about" */ '../views/login/binding.vue')
  // },
  //---------------------首页-------------------------------------
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { title: "首页" },
  },
  {
    path: "/home/memorialHallList",
    name: "memorialHallList",
    meta: { title: "首页" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/home/memorialHallList.vue"
      ),
  },
  {
    path: "/search",
    name: "search",
    meta: { title: "搜索" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/search.vue"),
  },
  //---------------------纪念馆-------------------------------------
  {
    path: "/memorialHall/:id",
    name: "memorialHall",
    meta: { title: "纪念馆" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/memorialHall/index.vue"),
  },
  {
    path: "/memorialHall/worship/:id",
    name: "worship",
    meta: { title: "祭拜" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/worship.vue"
      ),
  },
  {
    path: "/memorialHall/realTombPicList/:id",
    name: "realTombPicList",
    meta: { title: "云墓背景" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/realTombPicList.vue"
      ),
  },
  {
    path: "/memorialHall/share/:id",
    name: "share",
    meta: { title: "分享" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/memorialHall/share.vue"),
  },
  {
    path: "/memorialHall/lighting/:id",
    name: "lighting",
    meta: { title: "点灯" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/lighting.vue"
      ),
  },
  {
    path: "/createMemorialHall",
    name: "createMemorialHall",
    meta: { title: "创建纪念馆" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/createMemorialHall.vue"
      ),
  },
  {
    path: "/memorialHall/album/:id",
    name: "album",
    meta: { title: "相册" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/album/index.vue"
      ),
  },
  {
    path: "/memorialHall/albumLife/:id",
    name: "albumLife",
    meta: { title: "生平" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/album/albumLife.vue"
      ),
  },
  {
    path: "/memorialHall/eulogyManagement/:id",
    name: "eulogyManagement",
    meta: { title: "悼文管理" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/album/eulogyManagement.vue"
      ),
  },
  {
    path: "/memorialHall/addEulogy/:id",
    name: "addEulogy",
    meta: { title: "新增悼文" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/album/addEulogy.vue"
      ),
  },
  {
    path: "/memorialHall/messageManagement/:id",
    name: "messageManagement",
    meta: { title: "留言管理" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/album/messageManagement.vue"
      ),
  },
  {
    path: "/memorialHall/recallingSpace/:id",
    name: "recallingSpace",
    meta: { title: "追忆空间" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/recallingSpace/index.vue"
      ),
  },
  {
    path: "/memorialHall/manageCenter/:id",
    name: "manageCenter",
    meta: { title: "管理中心" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/manageCenter/index.vue"
      ),
  },
  {
    path: "/memorialHall/familyList/:id",
    name: "familyList",
    meta: { title: "亲友管理" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/manageCenter/familyList.vue"
      ),
  },
  {
    path: "/memorialHall/basicInfo/:id",
    name: "basicInfo",
    meta: { title: "基本信息" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/manageCenter/basicInfo.vue"
      ),
  },
  {
    path: "/memorialHall/memorialHallSetting/:id",
    name: "memorialHallSetting",
    meta: { title: "纪念馆设置" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/memorialHallSetting/index.vue"
      ),
  },
  {
    path: "/memorialHall/selectMusic/:id",
    name: "selectMusic",
    meta: { title: "选择音乐" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/memorialHallSetting/selectMusic.vue"
      ),
  },
  {
    path: "/memorialHall/selectHomeBg/:id",
    name: "selectHomeBg",
    meta: { title: "纪念馆首页背景" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/memorialHallSetting/selectHomeBg.vue"
      ),
  },
  {
    path: "/memorialHall/selectWorshipBg/:id",
    name: "selectWorshipBg",
    meta: { title: "纪念馆祭拜背景" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/memorialHallSetting/selectWorshipBg.vue"
      ),
  },
  {
    path: "/memorialHall/upgradeDoubleRoom/:id",
    name: "upgradeDoubleRoom",
    meta: { title: "升级双人馆" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/memorialHall/memorialHallSetting/upgradeDoubleRoom.vue"
      ),
  },
  //---------------------预约-------------------------------------
  {
    path: "/appointment",
    name: "appointment",
    meta: { title: "预约" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/appointment/index.vue"),
  },
  {
    path: "/appointment/appointmentSacrificeSweep",
    name: "appointmentSacrificeSweep",
    meta: { title: "预约祭扫" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/index.vue"
      ),
  },
  {
    path: "/appointment/appointmentTime",
    name: "appointmentTime",
    meta: { title: "选择预约时间" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/appointmentTime.vue"
      ),
  },
  {
    path: "/appointment/editAppointmentInfo",
    name: "editAppointmentInfo",
    meta: { title: "填写预约信息" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/editAppointmentInfo.vue"
      ),
  },
  {
    path: "/appointment/appointmentInfo/:id",
    name: "appointmentInfo",
    meta: { title: "预约信息" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/appointmentInfo.vue"
      ),
  },
  {
    path: "/appointment/appointmentSuccess",
    name: "appointmentSuccess",
    meta: { title: "预约成功" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/appointmentSuccess.vue"
      ),
  },
  {
    path: "/appointment/appointmentHistory",
    name: "appointmentHistory",
    meta: { title: "预约历史" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/appointmentHistory.vue"
      ),
  },
  {
    path: "/appointment/appointmentDetail/:id",
    name: "appointmentDetail",
    meta: { title: "预约历史" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentSacrificeSweep/appointmentDetail.vue"
      ),
  },
  {
    path: "/appointment/actingSacrificeSweep",
    name: "actingSacrificeSweep",
    meta: { title: "代为祭扫" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/actingSacrificeSweep/index.vue"
      ),
  },
  {
    path: "/appointment/serviceItems",
    name: "serviceItems",
    meta: { title: "服务项目" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/actingSacrificeSweep/serviceItems.vue"
      ),
  },
  {
    path: "/appointment/order",
    name: "order",
    meta: { title: "订单确认" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/actingSacrificeSweep/order.vue"
      ),
  },
  {
    path: "/appointment/orderList",
    name: "orderList",
    meta: { title: "订单列表" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/actingSacrificeSweep/orderList.vue"
      ),
  },
  {
    path: "/appointment/orderDetail/:id",
    name: "orderDetail",
    meta: { title: "订单详情" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/actingSacrificeSweep/orderDetail.vue"
      ),
  },
  {
    path: "/appointment/appointmentHistory",
    name: "actingAppointmentHistory",
    meta: { title: "预约历史" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/actingSacrificeSweep/appointmentHistory.vue"
      ),
  },
  {
    path: "/appointment/appointmentQuery",
    name: "appointmentQuery",
    meta: { title: "预约查询" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/appointment/appointmentQuery/index.vue"
      ),
  },
  //---------------------动态-------------------------------------
  {
    path: "/news",
    name: "news",
    meta: { title: "动态" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/index.vue"),
  },
  {
    path: "/newsDetail/:id",
    name: "newsDetail",
    meta: { title: "详情" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/newsDetail.vue"),
  },
  //---------------------个人中心-------------------------------------
  {
    path: "/my",
    name: "my",
    meta: { title: "我的" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/my/index.vue"),
  },
  {
    path: "/my/recharge",
    name: "recharge",
    meta: { title: "充值中心" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/my/recharge/index.vue"),
  },
  {
    path: "/my/rechargeList",
    name: "rechargeList",
    meta: { title: "充值历史" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/recharge/rechargeList.vue"
      ),
  },
  {
    path: "/my/cemeteryBinding",
    name: "cemeteryBinding",
    meta: { title: "陵园绑定" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/cemeteryBinding/index.vue"
      ),
  },
  {
    path: "/my/bindSuccess",
    name: "bindSuccess",
    meta: { title: "绑定成功" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/cemeteryBinding/bindSuccess.vue"
      ),
  },
  {
    path: "/my/iFollowMemorialHalls",
    name: "iFollowMemorialHalls",
    meta: { title: "我关注的纪念馆" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/iFollowMemorialHalls/index.vue"
      ),
  },
  {
    path: "/my/iManageMemorialHalls",
    name: "iManageMemorialHalls",
    meta: { title: "我管理的纪念馆" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/iManageMemorialHalls/index.vue"
      ),
  },
  {
    path: "/my/personalData",
    name: "personalData",
    meta: { title: "个人资料" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/personalData/index.vue"
      ),
  },
  {
    path: "/my/messageManagement",
    name: "myMessageManagement",
    meta: { title: "留言管理" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/messageManagement/index.vue"
      ),
  },
  {
    path: "/my/eulogyManagement",
    name: "myEulogyManagement",
    meta: { title: "悼文管理" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/eulogyManagement/index.vue"
      ),
  },
  {
    path: "/my/eulogyDetail/:id",
    name: "eulogyDetail",
    meta: { title: "悼文详情" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/eulogyManagement/eulogyDetail.vue"
      ),
  },
  {
    path: "/my/examineList",
    name: "examineList",
    meta: { title: "审核列表" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/examineList/index.vue"
      ),
  },
  {
    path: "/my/examineListDetail/:id",
    name: "examineListDetail",
    meta: { title: "订单详情" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/examineList/examineListDetail.vue"
      ),
  },
  {
    path: "/my/myHome",
    name: "myHome",
    meta: { title: "我的家园" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/my/myHome/index.vue"),
  },
  {
    path: "/my/myFamily",
    name: "myFamily",
    meta: { title: "我的家人" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/myHome/myFamily/index.vue"
      ),
  },
  {
    path: "/my/blessingTree",
    name: "blessingTree",
    meta: { title: "祈福树" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/myHome/blessingTree/index.vue"
      ),
  },
  {
    path: "/my/myBlessing",
    name: "myBlessing",
    meta: { title: "我的祈福" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/myHome/blessingTree/myBlessing.vue"
      ),
  },
  {
    path: "/my/blessingCard",
    name: "blessingCard",
    meta: { title: "我的祈福" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/myHome/blessingTree/blessingCard.vue"
      ),
  },
  {
    path: "/my/writeBless/:id",
    name: "writeBless",
    meta: { title: "我的祈福" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/myHome/blessingTree/writeBless.vue"
      ),
  },
  {
    path: "/my/reservationWriteOff",
    name: "reservationWriteOff",
    meta: { title: "预约核销" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/my/reservationWriteOff/index.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.title);
  //to 要去的路由配置
  //from 当前的路由配置
  //next 一定要调用，让to的路由配置继续生效，比如如果去登陆直接next(),否则判断token是否存在，如果存在就next()
  // var ua = window.navigator.userAgent.toLowerCase();
  // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
  //   //微信环境
  //   if(localStorage.getItem('openId')){
  //     if(to.meta.next) return next()
  //     if (to.path === '/login') return next() ;//使用return，不需要写else
  //     //判断其他页面是否有token
  //     const token = localStorage.getItem('token');
  //     //存在继续往后走
  //     if (token) return next();
  //     next('/login')
  //   }else{
  //     if(to.path=='/auth'){
  //       next()
  //     }else{
  //       next('/auth')
  //     }
  //   }
  // }else{
  //   //浏览器
  //   if(to.meta.next) return next()
  //     if (to.path === '/login') return next() ;//使用return，不需要写else
  //     //判断其他页面是否有token
  //     const token = localStorage.getItem('token');
  //     //存在继续往后走
  //     if (token) return next();
  //     next('/login')
  // }
  if (to.meta.next) return next();
  if (to.path === "/login") return next(); //使用return，不需要写else
  //判断其他页面是否有token
  const token = localStorage.getItem("token");
  //存在继续往后走
  if (token) return next();
  next("/login");
});
export default router;
