import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.scss' 
Vue.config.productionTip = false
//时间插件
import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs;
import animated from 'animate.css';
Vue.use(animated)
localStorage.setItem('firstLink',window.location.href)
//vant 
import { Image as VanImage,Tag,CountDown,Notify ,Sidebar,DatetimePicker, IndexBar, IndexAnchor,SidebarItem,SwipeCell,Switch,Overlay,RadioGroup,Radio,Button,ImagePreview,Toast,Dialog,Popup,NavBar, Step, Steps,Card,CheckboxGroup,Checkbox,SubmitBar,Icon,Stepper,Picker,Tabbar, TabbarItem,Tab, Calendar ,Collapse, CollapseItem,Tabs,Form,Field,Search,Swipe, SwipeItem,Lazyload,List,PullRefresh,Empty,Cell, CellGroup,Uploader,Area} from 'vant';
Vue.use(Button).use(Switch).use(SwipeCell).use(NavBar).use(RadioGroup).use(Radio).use(Icon).use(Tabbar).use(Card).use(TabbarItem).use(Tab).use(Tabs).use(Form).use(Field).use(Search).use(Swipe).use(SwipeItem).use(Lazyload).use(Checkbox).use(CheckboxGroup)
.use(Tag).use(List).use(VanImage).use(CountDown).use(Sidebar).use(IndexBar).use(DatetimePicker).use(IndexAnchor).use(Notify).use(SidebarItem).use(PullRefresh).use(ImagePreview).use(Empty).use(Step).use(Steps).use(Cell).use(Overlay).use(CellGroup).use(Uploader).use(Area).use(Popup).use(Calendar).use(Collapse).use(CollapseItem).use(Picker).use(Stepper).use(SubmitBar)
Vue.prototype.$toast = Toast;
Vue.prototype.$popup = Popup;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$notify = Notify;

import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//300s延迟
// import FastClick from 'fastclick'
// FastClick.attach(document.body);
//全局过滤器
import * as filters from '@/filters/timeFormat.js'
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
// 引入指令
// import vDrag from '@/utils/drag'
// Vue.directive('drag', vDrag)
Vue.directive('drag',el=>{
  let l=0;
  let t=0;
  el.onmousedown=function(e){
      console.log(e);
      //计算出元素距离上边和左边的距离（鼠标点击的位置-元素的位置）
      //这个应该能理解吧
      var disX=e.clientX-el.offsetLeft;
      var disY=e.clientY-el.offsetTop;
      document.onmousemove = function(e){
          //鼠标要按住不松开移动才行，松开就会触发onmouseup的事件
          //计算出元素移动后的位置（鼠标位置-元素初始的disX/disY）
           l=e.clientX-disX;
           t=e.clientY-disY;
          el.style.left=l+'px';
          el.style.top=t+'px';
      }
      document.onmouseup=function(e){
          document.onmousemove=null;
          document.onmouseup=null;
          el.style.left=l+'px';
          el.style.top=t+'px';
      }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
