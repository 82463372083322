<template>
    <div class="home_item_title">
        <div class="title_left">
            <div class="line"></div>
            <div class="title">{{title}}</div>
        </div>
        <div class="title_right">
           <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        props:{
            title:{
                type:String,
                default:''
            },
            path:{
                type:String,
                default:''
            }
        }

    }
</script>

<style lang="scss" scoped>
.home_item_title{
    width: 100%;
    height: 0.35rem;

    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
    -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
    .title_left{
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        .line{
            width: 0.03rem;
            height:0.17rem;
            background: $defaultColor;
            border-radius: 0.01rem;
            margin-right: 0.04rem;
        }
        .title{
            font-size: 15px;
        }
    }
    .title_right{
        .check_more{
            display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
            -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
            font-size: 12px;
        }
    }
}
</style>